/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    p: "p",
    img: "img",
    h1: "h1"
  }, _provideComponents(), props.components), {Hero, Columns, Column, List, Video, Typography, Media, Background, Testimonial, Comparison, Link, LogoCloud, Form, Resource} = _components;
  if (!Background) _missingMdxReference("Background", true);
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Comparison) _missingMdxReference("Comparison", true);
  if (!Form) _missingMdxReference("Form", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!Link) _missingMdxReference("Link", true);
  if (!List) _missingMdxReference("List", true);
  if (!LogoCloud) _missingMdxReference("LogoCloud", true);
  if (!Media) _missingMdxReference("Media", true);
  if (!Resource) _missingMdxReference("Resource", true);
  if (!Testimonial) _missingMdxReference("Testimonial", true);
  if (!Typography) _missingMdxReference("Typography", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    background: "deepPurple",
    backgroundImage: "primary",
    color: "white01",
    headline: "The Integration Platform for B2B SaaS",
    text: "Launch native integrations to the other products your customers use with the embedded integration platform purpose-built for B2B SaaS."
  }), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "saas-companies-prefer-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#saas-companies-prefer-prismatic",
    "aria-label": "saas companies prefer prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SaaS companies prefer Prismatic"), React.createElement(_components.h3, {
    id: "the-best-integration-platform-for-b2b-saas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-best-integration-platform-for-b2b-saas",
    "aria-label": "the best integration platform for b2b saas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The best integration platform for B2B SaaS:"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Easily integrate to your customers' other apps "), React.createElement("li", null, "Handle customer-specific config & credentials"), React.createElement("li", null, "Embed an integration marketplace in your product"), React.createElement("li", null, "Management tools to deploy and support integrations"))), React.createElement(Column, {
    sm: 6
  }, React.createElement(Video, {
    image: "/images/landing-page/video-thumbnail.png",
    videoId: "488710278"
  }))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2",
    spacing: "medium"
  }, React.createElement(_components.p, null, "Essential Features")), "\n", React.createElement(Columns, {
    spacing: "small"
  }, React.createElement(Media, {
    title: "Low-Code Integration Designer",
    image: "/images/landing-page/integration-icon.png",
    imageBorder: false
  }), React.createElement(Media, {
    title: "Embedded Integration Marketplace",
    image: "/images/landing-page/embeddable-ux-icon.png",
    imageBorder: false
  }), React.createElement(Media, {
    title: "Easy Customer Configuration",
    image: "/images/landing-page/customer-deployment-icon.png",
    imageBorder: false
  })), "\n", React.createElement(Background, {
    background: "gray03",
    color: "gray09",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Testimonial, {
    id: "mansourw"
  })), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2",
    spacing: "large"
  }, React.createElement(_components.p, null, "What's the difference between an embedded vs traditional integration platform?")), "\n", React.createElement(Comparison, {
    name: "ipaas-vs-embedded"
  }), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h4",
    spacing: "large"
  }, React.createElement(_components.p, null, "Purpose-built for Native SaaS Integrations")), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "configurable-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#configurable-integrations",
    "aria-label": "configurable integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Configurable Integrations"), React.createElement(_components.p, null, "Scale your integrations faster with configuration variables. Build once and deploy across your customers."), React.createElement(Link, {
    href: "/platform/integration-designer",
    label: "See How",
    color: "secondary"
  })), React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "seamless-embedding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#seamless-embedding",
    "aria-label": "seamless embedding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Seamless Embedding"), React.createElement(_components.p, null, "Add native integrations in a white-labeled marketplace that fits your product and improves your CX."), React.createElement(Link, {
    href: "/platform/embedded-integration-marketplace",
    label: "See How",
    color: "secondary"
  })), React.createElement(Column, {
    sm: 4
  }, React.createElement(_components.h3, {
    id: "customer-self-service",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customer-self-service",
    "aria-label": "customer self service permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customer Self-Service"), React.createElement(_components.p, null, "Embed a self-serve integration marketplace quickly and get back to what matters."), React.createElement(Link, {
    href: "/solutions/onboard-customers-faster/",
    label: "See How",
    color: "secondary"
  }))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h2"
  }, React.createElement(_components.p, null, "Scale Your Integration Strategy Fast with Prismatic")), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "flexible-integration-designer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#flexible-integration-designer",
    "aria-label": "flexible integration designer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Flexible Integration Designer"), React.createElement(_components.h3, {
    id: "combine-pre-built-and-custom-connectors-to-build-powerful-workflows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combine-pre-built-and-custom-connectors-to-build-powerful-workflows",
    "aria-label": "combine pre built and custom connectors to build powerful workflows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combine pre-built and custom connectors to build powerful workflows"), React.createElement(_components.p, null, "The intuitive low-code designer empowers non-devs to create integrations by assembling connectors and other components."), React.createElement(_components.p, null, "And, devs can write code with our Node.js SDK to create custom connectors to any third-party app your customers use.")), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/integration-designer.png",
    alt: "Integration Designer"
  })))), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/embedded-integration-marketplace.png",
    alt: "Dummy Image"
  }))), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "embedded-integration-marketplace",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#embedded-integration-marketplace",
    "aria-label": "embedded integration marketplace permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Embedded Integration Marketplace"), React.createElement(_components.h3, {
    id: "get-back-to-core-product-work-by-embedding-a-marketplace-with-a-few-lines-of-code",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-back-to-core-product-work-by-embedding-a-marketplace-with-a-few-lines-of-code",
    "aria-label": "get back to core product work by embedding a marketplace with a few lines of code permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get back to core product work by embedding a marketplace with a few lines of code"), React.createElement(_components.p, null, "Embed a self-serve integration marketplace quickly and get back to what matters. Plus, Prismatic provides the infrastructure and logging, monitoring, alerting tools dev and DevOps teams need to keep integrations up and running."))), "\n", React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h2, {
    id: "configurable-integration-cx",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#configurable-integration-cx",
    "aria-label": "configurable integration cx permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Configurable Integration CX"), React.createElement(_components.h3, {
    id: "your-customers-active-and-manage-their-integrations-without-involving-your-team",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#your-customers-active-and-manage-their-integrations-without-involving-your-team",
    "aria-label": "your customers active and manage their integrations without involving your team permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Your customers active and manage their integrations, without involving your team"), React.createElement(_components.p, null, "Users can self-activate integrations with just a few clicks to connect their third-party apps & choose config options."), React.createElement(_components.p, null, "Config screens provide full control over everything from layout and labels to defaults and hint text. Create the perfect guided activation experience for your users with Prismatic.")), React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/landing-page/self-activation.png",
    alt: "GraphQL API Explorer"
  })))), "\n", React.createElement(Background, {
    background: "gray03",
    color: "gray09",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Testimonial, {
    id: "pames2"
  })), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h3"
  }, React.createElement(_components.p, null, "Trusted by product and dev teams from startups to Fortune 100")), "\n", React.createElement(LogoCloud, {
    logos: "/images/logo-big-tin-can.svg,/images/logo-elastic.svg,/images/logo-sisu.svg,/images/logo-raven-industries.svg,/images/logo-sound-thinking.svg,/images/logo-ingenious.svg,/images/logo-hatch.svg,/images/logo-deepstream.svg",
    fullWidth: true
  }), "\n", React.createElement(Background, {
    backgroundImage: "dark",
    background: "deepPurple",
    color: "white01",
    fullWidth: true,
    spacing: "medium"
  }, React.createElement(Columns, null, React.createElement(Column, {
    sm: 6
  }, React.createElement(_components.h1, {
    id: "request-your-demo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#request-your-demo",
    "aria-label": "request your demo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Request Your Demo"), React.createElement(_components.p, null, "We'd love to show you why Prismatic is the leading embedded integration platform. Loved by product leaders and engineering teams alike, Prismatic helps you:"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Launch integrations in hours, not months"), React.createElement("li", null, "Build complex integrations to any application"), React.createElement("li", null, "Drive user adoption and reduce churn"), React.createElement("li", null, "Increase time for core product work")), React.createElement(Columns, {
    spacing: "large"
  }, React.createElement(Media, {
    image: "/images/landing-page/g2-badge-1.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-2.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-3.png",
    imageBorder: false
  }), React.createElement(Media, {
    image: "/images/landing-page/g2-badge-4.png",
    imageBorder: false
  }))), React.createElement(Column, {
    sm: 6
  }, React.createElement(Form, {
    name: "integration-platform-demo",
    spacing: "medium"
  })))), "\n", React.createElement(Typography, {
    align: "center",
    variant: "h4",
    textTransform: "uppercase"
  }, React.createElement(_components.p, null, "Resources")), "\n", React.createElement(Columns, {
    spacing: "medium",
    gap: 3
  }, React.createElement(Resource, {
    backgroundImage: "card-medium-light-v1",
    description: "Learn all about embedded iPaaS: what it is, its benefits, and how to choose the best one for your team.",
    link: "/resources/embedded-ipaas-scalable-integration-strategy/",
    color: "gray12",
    overline: "Guides",
    title: "Embedded iPaaS: The Integration Strategy for B2B Software Companies"
  }), React.createElement(Resource, {
    backgroundImage: "card-large-dark-v1",
    description: "Native integrations improve the customer experience for B2B software companies. Should you build or buy?",
    link: "/resources/build-vs-buy-adding-native-integrations-to-your-product/",
    overline: "Guides",
    title: "Native Integration: A Guide to Building vs Buying Native Integrations"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
